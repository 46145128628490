import { useState, useContext, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import Styles from '../../../../styles/pages/home.module.scss';
import { GeneralConfigContext } from '../../../general-config-provider';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';

import {
  formatterMoney,
  splitField,
  getMunicipalityDepartmentID,
  getMunicipalityName,
  getDepartmentName,
  getTouristServiceTypeMuncipalityID,
} from '../../../../shared/utils';

type RecommendedOffersSectionType = {
  title?: string;
  offers?: any[];
};

export default function RecommendedOffersSection({
  title,
  offers,
}: RecommendedOffersSectionType) {
  const router = useRouter();
  const [locale, setLocale] = useState(router?.locale);
  const { generalConfig } = useContext(GeneralConfigContext);
  const [numberState, setNumberState] = useState(1);
  const inputElement = useRef<HTMLDivElement>(null);
  const t = useTranslations('townships.Turistic');

  let lenghtList = offers.length;

  const municipalityID = (offer) => {
    return getTouristServiceTypeMuncipalityID(offer);
  };

  const scrollLeft = (number: number) => {
    if (inputElement !== null) {
      // window.scroll(0,inputElement.current.offsetLeft)
      if (number === 1 && numberState < lenghtList) {
        inputElement.current.scrollLeft += 310;
        setNumberState(numberState + 1);
      } else if (number === 2 && numberState > 1) {
        inputElement.current.scrollLeft -= 310;
        setNumberState(numberState - 1);
      }
    }
  };

  useEffect(() => {
    if (offers.length === 0) setNumberState(0);
    return () => {
      setNumberState(1);
    };
  }, [offers]);

  const redirection = (id: number) => {
    router.replace('/servicio/' + id).then();
  };
  return (
    <div className={Styles.content_offers_tourist}>
      <p>{title}</p>
      <div className={Styles.content_cards_offers} ref={inputElement}>
        {offers.map((item) => (
          <div
            className={Styles.content_cards_offers_item}
            key={item.id_oferta}
            style={{ overflow: 'hidden' }}
            onClick={() => redirection(item.id_oferta)}
          >
            <img
              alt="image"
              src={
                process.env.NEXT_PUBLIC_STORAGE_URL +
                item?.imagenes[0]?.ImagenURL
              }
            />
            <p className={Styles.department_text}>
              {getDepartmentName(
                getMunicipalityDepartmentID(
                  municipalityID(item),
                  generalConfig?.ciudad
                ),
                generalConfig?.departamento
              )}
              ,{' '}
              {getMunicipalityName(municipalityID(item), generalConfig?.ciudad)}
            </p>
            <p className={Styles.title_text}>
              {splitField(item.nombre, locale)}
            </p>
            <p className={Styles.subtitle_text}>
              {t('By')} <b>{item.PST.razon_social}</b>
            </p>
            <div className={Styles.group_star}>
              <img alt="image" src="/images/municipios/star.svg" />
              <p>{item.calificacion}</p>
            </div>
            <p className={Styles.card_paragraph}>
              {splitField(item.descripcion, locale)}
            </p>
            <div className={Styles.group_price}>
              <p className={Styles.desde}>{t('From')}</p>
              <p className={Styles.price_sub}>
                {formatterMoney.format(item.valor)}
              </p>
              <p className={Styles.price_green}>
                {formatterMoney.format(item.valor_calculado)}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className={Styles.control_buttons_slider}>
        <div
          className={classNames(Styles.button_slider, Styles.rotate)}
          onClick={() => scrollLeft(2)}
        >
          <img src="/icons/chevronright.svg" alt="arrow" id="btnScrollLeft" />
        </div>
        <p>{numberState + ' / ' + lenghtList}</p>
        <div className={Styles.button_slider} onClick={() => scrollLeft(1)}>
          <img src="/icons/chevronright.svg" alt="arrow" id="btnScrollLeft" />
        </div>
      </div>
    </div>
  );
}
